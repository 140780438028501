import {
  subscribeToUserEventsFx,
  eventMeetSubscriptionSelfInitReceived,
  eventMeetSpaceLeave,
  eventMeetSpaceJoinedSuccessfully,
  eventToggleCameraOnOff,
  eventMicrophoneIconPressed,
  $connectionState,
} from "@sign/meetspace";
import { merge, sample } from "effector";

import { postpone } from "@sign/shared/lib";

void subscribeToUserEventsFx();

if (process.env.NODE_ENV !== "development") {
  /**
   * Wait until webrtc is initialized and enable camera and microphone automatically.
   */
  sample({
    clock: postpone({
      clock: eventMeetSubscriptionSelfInitReceived,
      reset: merge([eventMeetSpaceLeave, eventMeetSpaceJoinedSuccessfully]),
      until: $connectionState.map((c) => c === "connected"),
    }),
    target: [eventToggleCameraOnOff, eventMicrophoneIconPressed],
  });
}
