import { LinkingOptions } from "@react-navigation/native";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";

export type RootStackParamList = {
  // Auth: NavigatorScreenParams<AuthStackParamsList>;
  CallRoomScreen: { spaceId: string; token: string | undefined };
  NotFoundScreen: undefined;
  NotFoundScreenHome: undefined;
  // Main: NavigatorScreenParams<MainStackParamsList>;
};
export type RootStackScreenProps<T extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, T>;

// export type MainStackParamsList = {
//   HomeScreen:
//     | { createEvent?: true; createRoom?: true; edit?: string }
//     | undefined;
//   MeetSpaceRecordingsScreen: {
//     recordingId?: string | undefined;
//     spaceId: string;
//   };
//   ScheduleScreen:
//     | { createEvent?: true; createRoom?: true; edit?: string }
//     | undefined;
//   SettingsScreen: { sectionId: number };
// };
// export type MainStackScreenProps<T extends keyof MainStackParamsList> =
//   CompositeScreenProps<
//     NativeStackScreenProps<MainStackParamsList, T>,
//     RootStackScreenProps<keyof RootStackParamList>
//   >;

// export type AuthStackParamsList = {
//   LoginScreen: undefined;
//   NicknameScreen: undefined;
//   PasswordConfirmScreen: { code: string };
//   PasswordForgotScreen: undefined;
//   RegistrationConfirmScreen: undefined;
//   RegistrationFinalScreen: undefined;
//   RegistrationScreen: undefined;
// };

// export type AuthStackScreenProps<T extends keyof AuthStackParamsList> =
//   CompositeScreenProps<
//     NativeStackScreenProps<AuthStackParamsList, T>,
//     RootStackScreenProps<keyof RootStackParamList>
//   >;

export const linking: LinkingOptions<RootStackParamList> = {
  config: {
    screens: {
      CallRoomScreen: "event/:spaceId/:token?",
      // Main: {
      //   screens: {
      //     MeetSpaceRecordingsScreen: "recordings/:spaceId/:recordingId?",
      //   },
      // },
      NotFoundScreen: "*",
      NotFoundScreenHome: "",
    },
  },
  prefixes: [process.env.VITE_VHOST ?? ""],
};
