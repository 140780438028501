import {
  createNativeStackNavigator,
  NativeStackNavigationOptions,
} from "@react-navigation/native-stack";
import { lazy, memo, Suspense } from "react";

import { RootStackParamList } from "~/shared/config/navigation";

// eslint-disable-next-line react-memo/require-memo
// const AuthScreen = lazy(() => import("~/pages/Auth"));
// eslint-disable-next-line react-memo/require-memo
// const NicknameScreen = lazy(() => import("~/pages/Auth/nickname-screen"));
// eslint-disable-next-line react-memo/require-memo
// const MainScreen = lazy(() => import("~/pages/Main"));
// eslint-disable-next-line react-memo/require-memo
const CallRoomScreen = lazy(() => import("~/pages/CallRoomScreen"));
// eslint-disable-next-line react-memo/require-memo
const NotFoundScreen = lazy(() => import("~/pages/NotFoundScreen"));

const Stack = createNativeStackNavigator<RootStackParamList>();

const DEFAULT_SCREEN_OPTIONS: NativeStackNavigationOptions = {
  statusBarColor: "#000",
  title: "Glinka.digital",
};
export const StackNavigator = memo(() => (
  <Suspense>
    <Stack.Navigator
      initialRouteName="CallRoomScreen"
      screenOptions={DEFAULT_SCREEN_OPTIONS}
    >
      <Stack.Screen
        component={CallRoomScreen}
        name="CallRoomScreen"
        options={{
          headerShown: false,
          title: "Встреча",
        }}
      />
      <Stack.Screen
        component={NotFoundScreen}
        name="NotFoundScreen"
        options={{
          headerShown: false,
          title: "Ошибка",
        }}
      />
      <Stack.Screen
        component={NotFoundScreen}
        name="NotFoundScreenHome"
        options={{
          headerShown: false,
          title: "Ошибка",
        }}
      />
    </Stack.Navigator>
  </Suspense>
));
